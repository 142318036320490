import React from "react";

const divStyle = {
  margin: "2%",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  height: "100vh",
  flexDirection: "column"
};

const NotFoundPage = () => (
  <div style={divStyle}>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    <span>
      <iframe
        src="https://giphy.com/gifs/H7wajFPnZGdRWaQeu0/html5"
        width="500px"
        height="500px"
        frameBorder="0"
        class="giphy-embed"
        allowFullScreen
      ></iframe>
    </span>
  </div>
);

export default NotFoundPage;
